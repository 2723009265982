<template>
  <div class="item-content" v-show="visible">
    <el-form
      ref="childForm"
      @submit.native.prevent
      :rules="rules"
      :model="formLabelAlign"
      label-position="top"
    >

    <div class="item-tooltip-title">
      <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
      <el-tooltip
        class="item"
        effect="light"
        placement="bottom"
        v-if="fatherContent.displayReference.description"
      >
        <div slot="content">
          {{ fatherContent.displayReference.description }}
        </div>
        <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
      </el-tooltip>
    </div>
    <el-form-item prop="value">
      <div class="ve-select"
        v-bind:class="{ ve_disable: disabled || disableKeyPath}"
        @click="callSelect">
        {{showtext}}
        <i class="el-icon-arrow-right scan" style="opacity: .5"></i>
      <span
        style="color: rgb(198, 202, 209)"
        v-if="itemdata == ''"
      >{{fatherContent.displayReference.prompt || ''}}</span>
    </div>
    </el-form-item>
    </el-form>

    <el-drawer
      title=""
      :visible.sync="sheetVisible"
      direction="btt"
      :with-header="false"
      size="60%">
      <div class="drawer">
        <div class="header">
          <img src="@/assets/icons/close.png" alt="close" height="30" width="30" @click="sheetVisible = false">
        </div>
        <form id="myform" action="" onsubmit="return false;">
          <div class="top">
              <img src="@/assets/icons/search.png" alt="search" height="16" width="16" style="z-index: 2;margin-left: 10px">
              <input type="search" placeholder="Search" v-model="product_search" class="search" @keydown="handleProductSearch" />
          </div>
        </form>

        <div class="body">
          <div v-for="(item, index) in dataSource" :key="index" class="item" @click="handleChangeProduct(item)">
            <el-image
              lazy
              :src="spliceUrl(item.image)"
              :fit="'contain'"
            >
              <div slot="error" class="image-slot">
                <img src="@/assets/icons/default.png" width="44" height="44" />
              </div>
            </el-image>
            <span style="margin-left: 10px">{{ item.name }}</span>
          </div>
          <span v-if="dataSource.length === 0" class="no_data">{{ $t("app.no_data") }}</span>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import language from '@/utils/lang';
import {mapGetters} from "vuex";
import VeTrueApi from '@/api/vetrue';
import * as R from 'ramda';

export default {
  name: "sku-widget",
  uuid: "00000000-0000-0000-0000-000000100012",
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host","listHost", "contextData", "editStatus"],
  inject: {
    commonSave: {
      default: {}
    }
  },
  components: {},
  data() {
    return {
      itemdata: "",
      product_search: '',
      formLabelAlign: {
        value: ""
      },
      dataSource: [
        {
          name: this.$t("app.no_data"),
          value: -1
        }
      ],
      sheetVisible: false,
      selectedData: {}
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      dataPointType: "getDataPointType",
      getDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    rules() {
      return {
        value: [
          {
            required: this.fatherContent.controlReference.required,
            message: this.$t('app.required_error') ,
            trigger: "change"
          }
        ]
      };
    },
    formCannotEdit() {
      return (this.disabled || this.disableKeyPath)
    },
    showtext() {
      const filter = this.dataSource && this.dataSource.filter(
        item => this.itemdata === item.value
      )

      return filter && filter[0] && filter[0].name;
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    },
    hostKey(){
      return this.host + "." + this.fatherContent.key
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    disableKeyPath() {
      let failure = this.formStatus === 'failureedit'
      let draft = this.formStatus === 'draftedit'
      let initStatus = this.formStatus === 'init'
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey) || (!failure && !draft && !initStatus)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: val.value
        });
      },
      deep: true
    }
  },
  methods: {
    handleChangeProduct(value) {
      this.setValue(value);
      this.sheetVisible = false;
    },
    // 商品搜索
    handleProductSearch() {
      this.clearTimer()
      if (this.product_search && this.product_search.length > 0) {
        this.len = true
        // 带条件搜索
        this.timer = setTimeout(() => {
          this.getProjectProduct(this.product_search);
        }, 1000)
      } else {
        // 搜索全部
        if (this.len) {
          this.getProjectProduct();
        }
        if (this.product_search === '') {
          this.len = false
          return
        }
      }
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    },
    callSelect() {
      if (this.disabled || this.disableKeyPath) {
        return;
      }
      this.sheetVisible = true;
    },
    setValue(select) {
      if (select.value === -1) return;
      this.itemdata = select.value;
      const filter = this.dataSource.filter(
        item => select.value === item.value
      )[0];

      this.selectedData = {
        key: this.fatherContent.key,
        value: { uuid: filter.uuid, datavid: filter.value }
      };
      this.formLabelAlign.value = [
        {
          uuid: filter.uuid,
          datavid: filter.value,
          datauuid: filter.uuid
        }
      ];
      this.$emit("fatherCall", {
        key: this.fatherContent.key,
        value: [{
          uuid: filter.uuid,
          datavid: filter.value,
          datauuid: filter.uuid
        }]
      });
    },
    async getProjectProduct(search = '') {
      const query = this.$route.query;
      let project_uuid = query.project_uuid;
      const product = await VeTrueApi.getProjectProduct({ query, project_uuid, search });
      if (R.isEmpty(product)) return this.dataSource = [];
      this.dataSource = product.map(sku => {
        const dataVID = R.pathOr('', ['dcp_data', 'tc_dcp_data', 'dataVID'])(sku);
        const dataUUID = R.pathOr('', ['dcp_data', 'tc_dcp_data', 'dataUUID'])(sku);
        return {
          name: (sku.name ? sku.name : ""),
          image: sku.image,
          value: dataVID,
          uuid: dataUUID,
          datauuid: dataUUID,
          datavid: dataVID,
          method: this.setValue
        };
      });
    },
    spliceUrl(location) {
      return window.Config.bless_service_url + `/v1/public/attachment/${location}`;
    },
  },
  created() {},
  mounted() {
    this.itemdata = this.datamodel && this.datamodel[0].datavid || ""
    this.formLabelAlign.value = this.datamodel

    const dataSource = this.fatherContent.controlReference.dataSource.filter(
      item => item.indexOf("Remote") > -1
    );
    if (!dataSource.length) return;
    if (dataSource[0] === "Remote.SKUList") {
      this.getProjectProduct();
    }
  }
};
</script>
<style lang='scss' scoped>

.no_data {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: auto;
  margin-top: 100px;
}
.drawer {
  height: 100%;
  padding: 10px;
}

.header {
  display: flex;
  justify-content: flex-end;
  & > img {
    height: 30px;
    width: 30px;
  }
}

.top {
  display: flex;
  align-items: center;
  position: relative;
  margin: 25px;
  margin-bottom: 0px;
  z-index: 1;
  & > img {
    height: 16px;
    width: 16px;
  }
}

.body {
  height: 85%;
  padding: 25px;
  margin-bottom: 20px;
  overflow-y: auto;
}

::v-deep .el-image {
  height: 44px;
  width: 44px;
  & > img {
    height: 44px;
    width: 44px;
  }
}

.search {
  position: absolute;
  width: 100%;
  height: 48px;
  background-color: #F9F9F9;
  outline: none;
  border-radius: 2px;
  padding-left: 30px;
  font-size: 14px;
}

.item {
  display: flex;
  align-items: center;
  padding: 18px 0px;
  border-bottom: 1px solid #EEEEEE;
  font-size: 16px;
}

.item-content {
  padding-top: 10px;
  text-align: left;
  margin-bottom: 5px;
}
</style>
