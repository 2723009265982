<template>
<div class="form-item" v-show="visible">
  <el-form ref="childForm" :rules="rules" :model="formLabelAlign">
    <div class="item-tooltip-title">
      <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
      <el-tooltip class="item" effect="light" placement="bottom" v-if="fatherContent.displayReference.description">
        <div slot="content">
          {{ fatherContent.displayReference.description }}
        </div>
        <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
      </el-tooltip>
    </div>
    <el-form-item prop="value">
      <div class="gps-single">
        <div style="min-width:50px; height: 40px; display: flex; justify-content: center; align-items: center;">
          <template v-if="gpsSignalWeak">
            <span>{{$t('app.gps_signal_weak')}}</span>
            <img src="@/assets/icons/dcu/gps_single_weak.png" width="24px" height="24px" />
          </template>
        </div>
      </div>
      <el-input type="textarea" v-model="itemdata" :rows="2" disabled class="rt-input"></el-input>
      <div v-show="locationOk">
        <div :id="hostKey" style="marginTop: 10px; width: 100%; height: 200px" @click="editGPS"></div>
        <span style="color: #999">latitude: {{formLabelAlign.value && formLabelAlign.value.lat}}</span>
        <span style="marginLeft: 10px; color: #999">longitude: {{formLabelAlign.value && formLabelAlign.value.lon}}</span>
      </div>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import callApp from "@/services/sdk";
import language from "@/utils/lang";
import loadMap from '@/utils/map.js'
import * as R from 'ramda';
import VeTrueApi from '@/api/vetrue';

import {
  noop,
} from '@/utils/index'
import {
  mapGetters
} from 'vuex'

export default {
  uuid: "00000000-0000-0000-0000-000000100002",
  components: {},
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host", "listHost", "contextData", "editStatus"],
  data() {
    return {
      itemdata: "",
      label: "",
      formLabelAlign: {
        value: ""
      },
      map: null,
      veMap: null,
      mapType: '',
      locationOk: false,
      showMapAsConfig: true,
      hasGpsConfig: true,
      gpsSignalWeak: false,
      zoom: 12,
      mapTimer: null
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList',
      getChildPageContext: 'getChildPageContext'
    }),
    cannotEdit() {
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    },
    hostKey() {
      return this.host + "." + this.fatherContent.key
    },
    rules() {
      return {
        value: [{
          // required: this.hasGpsConfig,
          required: this.fatherContent.controlReference.required,
          message: this.$t('app.required_error'),
          trigger: 'blur'
        }]
      }
    },
    // 收货管理页面中的发货地址字段
    isReceiveGPS() {
      return this.fatherContent.key === 'gps_005'
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: val.value
        });
      },
      deep: true
    },
    disabled: {
      handler(val) {
        if (!val) {
          this.callNative();
        }
      }
    },
    datamodel: { // 监听 datamodel 变化，因为组件是全部更新，如果数据后更新，将无法看到最新数据
      immediate: true,
      handler: function (val) {
        if (val) {
          if (this.isReceiveGPS) {
            this.itemdata = this.formatAddress(val);
            this.$forceUpdate();
            this.formLabelAlign.value = val;
          }
        }
      }
    },
  },
  methods: {
    editGPS() {

      if (this.cannotEdit) {
        return
      }
      /// 不可编辑
      if (this.fatherContent.displayReference && this.fatherContent.displayReference.editable === false || this.disabled == true) {
        return
      }

      callApp(
        "getGPS", {
          type: "dataSource.gps",
          data: {
            edit: true,
            ...this.formLabelAlign.value
          }
        },
        "cbGetGPS"
      ).then(data => {
        if (data.gps) {
          this.$nextTick(() => {
            this.itemdata = this.formatAddress(data.gps);
          });

          this.formLabelAlign.value = data.gps;
          if (Object.keys(data.gps).length <= 0) {
            this.formLabelAlign.value = undefined
          }

          this.locationOk = true
          this.initMap()
          this.gpsSignalWeak = data.starCount <= 3
        }
      });
    },
    callNative() {
      const plat = this.$route.query.plat;
      if (plat && plat === 'WEB') {
        this.getIpLocation();
      } else {
        this.callAppGetGPS();
      }
    },
    parseJwt(token) {
      const base64Url = String(token).split('.')[1];
      const base64 = String(base64Url).replace('-', '+').replace('_', '/');
      const atob = (str) => global.atob ? global.atob(base64) : Buffer.from(str, 'base64').toString('binary');
      try {
        return JSON.parse(atob(base64));
      } catch (e) {
        return {};
      }
    },
    async getIpLocation() {
      const token = this.$route.query.token;
      const info = this.parseJwt(token);
      const accessToken = R.pathOr('',['tc', 'access_token'])(info);
      if (accessToken) {
        const gpsInfo = await VeTrueApi.getGpsInfo({
          token: accessToken,
          action: 'FindGeoByIP',
        });
        const data = R.pathOr({},['data', 'data'])(gpsInfo);
        let gps = {
          lat: data.latitude,
          lon: data.longitude,
          precision: 65,
          country: data.country_name,
          city: data.city_name,
          province: data.province_name || "",
        }

        this.$nextTick(() => {
          this.itemdata = this.formatAddress(gps);
        });

        this.formLabelAlign.value = gps;
        if (Object.keys(gps).length <= 0) {
          this.formLabelAlign.value = undefined
        }

        this.locationOk = true
        this.initMap()
      } else {
        this.$message({ message: this.$t('feture.token_failed'), type: "error", offset: 300, duration: 4000 , customClass: 'el-message-custom'});
      }
    },
    formatAddress(gps) {
      if (gps && gps.address) {
        return gps.address
      }
      if (gps) {
        var addressList =
          (gps && [gps.city || "", gps.province || "", gps.country || ""]) || [];
        var country = (gps && gps.country) || "";
        if (country === "中国" || country === "中國") {
          addressList = addressList.reverse();
        }
        return addressList.join(" ");
      }
      return "";
    },
    callAppGetGPS() {
      callApp(
        "getGPS", {
          type: "dataSource.gps",
          data: {}
        },
        "cbGetGPS"
      ).then(data => {
        if (data.gps) {
          this.$nextTick(() => {
            this.itemdata = this.formatAddress(data.gps);
          });

          this.formLabelAlign.value = data.gps;
          if (Object.keys(data.gps).length <= 0) {
            this.formLabelAlign.value = undefined
          }

          this.locationOk = true
          this.initMap()
          this.gpsSignalWeak = data.starCount <= 3
        }
      }).catch(error => {
        console.log("callNative_error", error)
      });
    },
    gpsSingleListener() {
      this.mapTimer && this.clearTimer()
      this.mapTimer = setInterval(() => {
        this.callNative()
      }, 30000)
    },
    clearTimer() {
      clearInterval(this.mapTimer)
    },
    initMap() {
      this.getCurrent(({
        lat: latitude,
        lon: longitude,
        ...ot
      } = {}) => {
        let pos = [longitude, latitude]
        this[`${this.mapType}AddMarker`](pos)
      })
    },
    getCurrent(cb) {
      if (this.formLabelAlign.value && (this.formLabelAlign.value.lat || this.formLabelAlign.value.lon)) {
        this.locationOk = true
        return this.initveMap(cb, {
          code: ~['中国', 'china', 'China', '中國'].indexOf(this.formLabelAlign.value.country) ? 'CN' : 'EN',
          ...this.formLabelAlign.value
        })
      }
      this.locationOk = false;
    },
    latlonFormat(data) {
      let { lat, lon, ...ot } = data
      return {
        lat: parseFloat(lat),
        lon: parseFloat(lon),
        ...ot
      }
    },
    GMapAddMarker([longitude, latitude] = []) {
      return new this.veMap.Marker({
        position: new this.veMap.LatLng(latitude, longitude),
        map: this.map
      })
    },
    AMapAddMarker(position) {
      var marker = new this.veMap.Marker({
        position,
        icon: new this.veMap.Icon({
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
          size: new this.veMap.Size(26, 34),
          imageSize: new this.veMap.Size(25, 34)
        }),
        offset: new this.veMap.Pixel(-13, -30),
        // 设置是否可拖拽
        draggable: false
      })
      marker.setMap(this.map)
    },
    initveMap(cb = (noop), data = {
      code: 'CN'
    }) {
      data = this.latlonFormat(data)
      let {
        code,
        lat: latitude,
        lon: longitude
      } = data
      this.mapType = code === 'CN' ? 'AMap' : 'GMap'
      loadMap(this.mapType, veMap => {
        this.map = null
        this.veMap = null
        this.veMap = veMap
        this.map =
          this.mapType === 'AMap' ?
          new veMap.Map(this.hostKey, {
            resizeEnable: true, // 是否监控地图容器尺寸变化
            zoom: this.zoom, // 初始化地图层级
            center: [longitude, latitude], // 初始化地图中心点
            lang: this.locLang,
            showMarker: true,
            zoomEnable: true,
            dragEnable: true
          }) :
          new veMap.Map(document.getElementById(this.hostKey), {
            center: new veMap.LatLng(latitude, longitude),
            zoom: this.zoom,
            mapTypeId: veMap.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            // navigationControl: false,
            // mapTypeControl: false,
            scaleControl: false,
            zoomControl: true,
            // fullscreenControl: false,
            draggable: true
          })

        cb(data)
      })
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    }
  },
  beforeDestroy() {
    this.clearTimer()
  },
  created() {},
  mounted() {

    this.label =
      this.fatherContent &&
      this.fatherContent.displayReference &&
      this.fatherContent.displayReference.label;

    if (this.datamodel && (this.datamodel.country || this.datamodel.province || this.datamodel.city)) {
      // 1 已经有地址
      this.itemdata = this.formatAddress(this.datamodel);
      this.$forceUpdate();
      this.formLabelAlign.value = this.datamodel;
    } else {
      // 1 地址为空，call native
      this.callNative();
    }

    this.initMap()
  }
};
</script>

<style lang="scss" scoped>
.rt-input {
  &.el-input.is-disabled .el-input__inner {
    background-color: #f5f7fa !important;
    border-color: transparent !important;
    color: black !important;
    cursor: not-allowed !important;
  }

  input:disabled {
    opacity: 1 !important;
  }
}

.gps-single {
  font-size: 13px;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
  top: -8px;
  right: 1px;

  span {
    line-height: 40px;
    padding-top: 6px;
  }
}
</style>
