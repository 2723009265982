<template>
<div class="item-content" v-show="visible">
  <div class="form-item ve-upload">
    <div @click="uploadFile">
      <el-form ref="childForm" label-position="top" :inline="true" @submit.native.prevent :model="formLabelAlign">
        <div class="item-tooltip-title" v-if="!isListItem">
          <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
          <el-tooltip class="item" effect="light" placement="bottom" v-if="fatherContent.displayReference.description">
            <div slot="content">
              {{ fatherContent.displayReference.description }}
            </div>
            <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
          </el-tooltip>
        </div>
        <el-form-item style="width: 100%" prop="filename">
          <el-upload action :class="{ hiddenUpload: fileList.length }" :file-list="fileList" class="audio-file" :on-remove="handleRemoveFile" :before-upload="beforeUpload" :disabled="disabled || cannotEdit">
            <div class="upload_text">
              <img src="@/assets/icons/dcu/audio_active.png" class="ve-upload-icon" v-if="fileList.length" />
              <img src="@/assets/new/upload_audio.png" class="ve-upload-icon" v-else />
              <span>{{$t('app.upload_audio')}}</span>
            </div>
          </el-upload>
        </el-form-item>

      </el-form>
    </div>
  </div>
</div>
</template>

<script>
import callApp from "@/services/sdk";
import language from '@/utils/lang'

import {
  mapGetters
} from 'vuex'

export default {
  uuid: "00000000-0000-0000-0000-000000100003",
  components: {},
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host", "listHost", "contextData", "editStatus", "father"],
  data() {
    return {
      itemdata: "",
      fileList: [],
      fileName: "",
      formLabelAlign: {}
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList',
      getChildPageContext: 'getChildPageContext'
    }),
    cannotEdit() {
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    content() {
      return Object.keys(this.jsonContent).length ?
        this.jsonContent :
        this.fatherContent;
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    },
    hostKey() {
      return this.host + "." + this.fatherContent.key
    },
    isListItem() {
      return this.father && this.father.itemType && Object.keys(this.father.itemType).length > 0
    }
  },
  methods: {
    beforeUpload() {
      return false;
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      )
    },
    uploadFile(e) {
      if (e.target.className !== "el-upload__input") return;
      callApp(
        "getFile", {
          type: "dataSource.file.file",
          data: {
            max: this.content.controlReference.max,
            type: this.content.controlReference.acceptedFileType || []
          }
        },
        "cbGetFile"
      ).then(data => {
        this.fileList = [];
        this.itemdata = {
          filehash: "",
          filelocation: "",
          filename: "",
          filesize: "",
          filetype: ""
        };
        this.formLabelAlign = {}
        this.$nextTick(() => {
          if (data.files) {
            this.itemdata = {
              ...data.files[0]
            };
            this.formLabelAlign = {
              ...data.files[0]
            };
            this.fileName = data.files[0].filename;
            this.fileList = [{
              url: this.spliceUrl(data.files[0].filehash),
              name: this.fileName
            }];
          }
        });
      });
      e.preventDefault();
    },
    handleRemoveFile() {
      this.fileName = "";
      this.fileList = [];
      this.formLabelAlign = {
        filehash: "",
        filelocation: "",
        filename: "",
        filesize: "",
        filetype: ""
      }
    },
    spliceUrl(location) {
      return window.Config.base_url + `/v1/public/attachment/${location}`;
    }
  },
  created() {},
  mounted() {
    this.fileName = this.datamodel && this.datamodel.filename;
    this.fileName &&
      (this.fileList = [{
        url: this.spliceUrl(this.datamodel.filehash),
        name: this.fileName
      }]);
    if (this.datamodel != undefined) {
      this.$nextTick(() => {
        this.formLabelAlign = this.datamodel;
      });
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: val.filelocation !== "" ? val : {}
        });
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.form-item {
  padding-top: 10px;
  text-align: center;
}

.upload_text {
  display: flex;
  font-size: 14px;
  height: 130px;
  color: #A9A9B0;
  font-weight: 500;
  width: 100%;
  justify-self: center;
  align-items: center;
  flex-direction: column;
}

.ve-upload-icon {
  width: 32px;
  position: relative;
  margin-top: 40px;
}

.el-upload__text em {
  color: #9b9b9b !important;
  font-weight: 500;
}

.el-upload-list--picture-card {
  display: flex;
  justify-content: center;

  .el-upload-list__item {
    width: 100%;
    height: auto;
    margin-right: 0;
  }
}

.el-upload--picture-card {
  display: flex;
  flex-direction: column;
  width: 100%;

  img {
    align-self: center;
  }
}

.el-upload__text {
  font-size: 14px;
  line-height: 48px;
}
</style>
