<template>
  <div v-if="isPlatApp">
    <!-- 根据key拆分的组件，层次结构发生了变化，多加上了一层，因此在增加的这层加上一个 ref="special"，从而有了这个分支判断 -->
    <vid-for-app
      v-if="item_name === 'vidlist'"
      ref="special"
      :jsonContent="jsonContent"
      :fatherContent="fatherContent"
      :datamodel="datamodel"
      :disabled="disabled"
      :host="host"
      :listHost="listHost"
      :contextData="contextData"
      @confirm="hanldGetChildInfo"
    />

    <!-- app 端，赋码追溯的批次信息和收货管理中的批次信息长得一样 -->
    <vid-for-app-big-vid
      v-if="item_name === 'bigvid' || item_name === 'delivervidlist'"
      ref="special"
      :jsonContent="jsonContent"
      :fatherContent="fatherContent"
      :datamodel="datamodel"
      :disabled="disabled"
      :host="host"
      :listHost="listHost"
      :contextData="contextData"
      @confirm="hanldGetChildInfo"
    />

    <shipment-batch-info
      v-if="item_name === 'receivevidlist'"
      ref="special"
      :jsonContent="jsonContent"
      :fatherContent="fatherContent"
      :datamodel="datamodel"
      :disabled="disabled"
      :host="host"
      :listHost="listHost"
      :contextData="contextData"
      @confirm="hanldGetChildInfo"
    />
  </div>

  <div v-else>
    <vid-for-web
      v-if="item_name === 'vidlist'"
      ref="special"
      :jsonContent="jsonContent"
      :fatherContent="fatherContent"
      :datamodel="datamodel"
      :disabled="disabled"
      :host="host"
      :listHost="listHost"
      :contextData="contextData"
      @confirm="hanldGetChildInfo"
    />

    <vid-for-web-big-vid
      v-if="item_name === 'bigvid'"
      ref="special"
      :jsonContent="jsonContent"
      :fatherContent="fatherContent"
      :datamodel="datamodel"
      :disabled="disabled"
      :host="host"
      :listHost="listHost"
      :contextData="contextData"
      @confirm="hanldGetChildInfo"
    />

    <shipment-batch-info
      v-if="item_name === 'receivevidlist'"
      ref="special"
      :jsonContent="jsonContent"
      :fatherContent="fatherContent"
      :datamodel="datamodel"
      :disabled="disabled"
      :host="host"
      :listHost="listHost"
      :contextData="contextData"
      @confirm="hanldGetChildInfo"
    />

    <receive-batch-info-for-web
      v-if="item_name === 'delivervidlist'"
      ref="special"
      :jsonContent="jsonContent"
      :fatherContent="fatherContent"
      :datamodel="datamodel"
      :disabled="disabled"
      :host="host"
      :listHost="listHost"
      :contextData="contextData"
      @confirm="hanldGetChildInfo"
    />
  </div>

</template>

<script>
import { mapGetters } from "vuex";

import VidForApp from '@/components/vidApp/vidForApp.vue';
import VidForAppBigVid from '@/components/vidApp/vidForAppBigVid.vue';
import VidForWeb from '@/components/vidWeb/vidForWeb.vue';
import VidForWebBigVid from '@/components/vidWeb/vidForWebBigVid.vue';
import ShipmentBatchInfo from '@/components/shipment/shipmentBatchInfo.vue';
import ReceiveBatchInfoForWeb from '@/components/receive/receiveBatchInfoForWeb.vue';

export default {
  name: "vid-widget",
  uuid: "00000000-0000-0000-0000-000000100007",
  props: [
    "jsonContent",
    "fatherContent",
    "datamodel",
    "disabled",
    "host",
    "listHost",
    "contextData",
  ],
  inject: {
    commonSave: {
      default: {}
    }
  },
  components: {
    VidForApp,
    VidForAppBigVid,
    VidForWeb,
    VidForWebBigVid,
    ShipmentBatchInfo,
    ReceiveBatchInfoForWeb,
  },
  data() {
    return {
      flag: 0,
      currentVid: "",
      type: "",
      orderVidData: [],
      rfidData: [],
      failRfidData: [],
      currentSource: "local.scanner",
      formLabelAlign: {
        value: ""
      },
      vidType: 0,
      showContent: "",
      isIos: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      idType: this.$t("app.id_type_custom"),
      vidSourceType: "vid",
      isViewVidList: false,
      selectedScannerOption: ""
    };
  },
  computed: {
    ...mapGetters({
      scanType: "getCurrentScanType",
      formStatus: "getFormStatus",
      bigVid: "getBigVid",
      dataPointType: "getDataPointType",
      dataInfo: "getDataInfo",
      editStatus: "getFormStatus",
      storeDisabledKeyPathList: 'getDisabledKeyPathList'
    }),
    isPlatApp() {
      return this.$route.query.plat === 'APP';
    },
    isCopy() {
      return this.$route.query && this.$route.query.copyRecord;
    },
    getIsViewVidList() {
      return this.type == 'view' || this.isViewVidList
    },
    showVIDSetting() {
      return true
    },
    vidCountLimit() {
      return this.fatherContent &&
        this.fatherContent.controlReference &&
        this.fatherContent.controlReference.max || 0
    },
    currentType() {
      return this.dataPointType === "collectionUnBind" ||
        this.dataPointType === "collectionBind" ||
        this.dataPointType === "productManage" ?
        ((this.fatherContent.key || "").toLowerCase() === "bigvid" ?
          "bigvid" :
          "subvid") :
        "";
    },
    item() {
      return this.fatherContent.controlReference;
    },
    item_name() {
      return this.fatherContent.key;
    },
    // itemdata: {
    //   get() {
    //     if (this.datamodel && this.datamodel.vidlist && !this.isCopy) {
    //       this.rfidData = this.datamodel.vidlist.map(vid => {
    //         let vidCode = 1;
    //         return {
    //           vid: vid,
    //           code: vidCode
    //         };
    //       });

    //       if (this.item.max === 1) return this.datamodel.vidlist[0];
    //       return this.datamodel.vidlist && this.datamodel.vidlist.length ?
    //         this.datamodel.vidlist.length :
    //         "";
    //     } else {
    //       return "";
    //     }
    //   },
    //   set(val) {
    //     if (!val) {
    //       this.$nextTick(_ => {
    //         this.showContent = "";
    //       });
    //       this.formLabelAlign.value = [];
    //       return false;
    //     }
    //     let data = {
    //       vidlist: val ? this.rfidData.map(data => data.vid) : [],
    //       type: 0
    //     };
    //     this.formLabelAlign.value = data.vidlist;
    //     let vidListLength = (data.vidlist && data.vidlist.length) || 0;
    //     if (this.item.max === 1 || vidListLength <= 1) {
    //       let showVid = data.vidlist[0];
    //       let vid = showVid.replace(
    //         showVid.substring(6, showVid.length - 6),
    //         "......"
    //       );
    //       if (data.vidlist[0].length <= 12) {
    //         vid = showVid;
    //       }
    //       this.$nextTick(_ => {
    //         this.showContent = vid;
    //       });
    //     } else {
    //       this.$nextTick(_ => {
    //         this.showContent = data.vidlist.length;
    //       });
    //     }
    //   }
    // },
    // customIdInShip() {
    //   return commonStoreModule.getCustomIdOnReceive;
    // },
  },
  watch: {
    bigVid: function () {
      this.flag++;
      this.$nextTick(() => {
        if (this.formStatus !== "init" && this.flag > 1) {
          if (this.currentType === "subvid") {
            this.rfidData = [];
            this.failRfidData = [];
            this.$nextTick(() => {
              this.itemdata = "";
            });
          }
        }
      });
    },
    formLabelAlign: {
      handler(val) {
        console.log("this.vidSourceType", this.vidSourceType);
        let type = this.selectedScannerOption.startsWith('nfc') ? 0 : 1;
        if (this.datamodel && !this.isCopy) {
          type = this.datamodel.type
        }
        this.vidType = type;
        var data = {
          vidlist: val.value,
          type: this.vidType,
          num: (val.value && val.value.length) || 0
        };
        if (val.value === undefined || val.value === "" || val.value === []) {
          data = undefined;
        }
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: data
        });
      },
      deep: true
    },
    customIdInShip: {
      immediate: true,
      handler(val) {
        if (val) {
          this.setCustomIdForReceive();
        }
      }
    }
  },
  created() {
    const _this = this;
    this.$store.state.vidlistComponents.push(this); // 缓存vid组件实例
    window["web.closeScanDialog"] = () => {
      this.$store.state.vidlistComponents.forEach(instance => {
        // 切换扫描类别时, 关闭扫描的弹窗
        // instance.$refs.vidList.dismiss();
      });
    };
  },
  mounted() {
    // if (this.datamodel && !this.isCopy) {
    //   if (this.datamodel.type === 1) {
    //     this.idType = this.$t("app.id_type_custom");
    //     this.vidSourceType = "customid";
    //     this.formLabelAlign.value = (this.datamodel.vidlist || []).map(item => {
    //       let idlist = item.split(",");
    //       return idlist[1] || idlist[0];
    //     });
    //   } else {
    //     this.idType = this.$t("app.id_type_vid");
    //     this.vidSourceType = "vid";
    //     this.formLabelAlign.value = this.datamodel.vidlist;
    //   }
    // }

    // if (this.datamodel && !this.isCopy && this.datamodel.type === 1) {
    //   this.datamodel.vidlist = this.datamodel.vidlist.map(item => {
    //     let idlist = item.split(",");
    //     return idlist[1] || idlist[0];
    //   });
    // }

    // if (this.item.max === 1) {
    //   let showVid = (this.datamodel && !this.isCopy) ? this.datamodel.vidlist[0] : "";
    //   let vid = showVid;
    //   if (showVid && showVid.length > 12) {
    //     vid = showVid.replace(
    //       showVid.substring(6, showVid.length - 6),
    //       "......"
    //     );
    //   }

    //   this.$nextTick(_ => {
    //     this.showContent = vid;
    //   });
    // } else {

    //   this.showContent =
    //     (this.datamodel && !this.isCopy &&
    //       this.datamodel.vidlist) ?
    //     this.datamodel.vidlist.length : "";

    //   if (this.datamodel && !this.isCopy && this.datamodel.vidlist && this.datamodel.vidlist.length == 1) {
    //     let showVid = this.datamodel && this.datamodel.vidlist[0];
    //     let vid = showVid;
    //     if (showVid && showVid.length > 12) {
    //       vid = showVid.replace(
    //         showVid.substring(6, showVid.length - 6),
    //         "......"
    //       );
    //     }

    //     this.$nextTick(_ => {
    //       this.showContent = vid;
    //     });
    //   }
    // }
  },
  methods: {
    hanldGetChildInfo(data) {
      this.$emit("fatherCall", {
        key: this.fatherContent.key,
        value: data
      });
    },
    // selectVidsFromOrder(vidList) {
    //   console.log("selected_vids_from_order", vidList);
    //   if (this.item.max > 1) {
    //     vidList.forEach(item => {
    //       if (item.vid && !this.isExisted(this.rfidData, item)) {
    //         this.rfidData.push(item);
    //         if (item.code != 1) {
    //           this.failRfidData.push({
    //             vid: item.vid,
    //             key: Math.random()
    //           });
    //         }
    //       } else {
    //         let index;
    //         if (item.code == 1) {
    //           this.failRfidData.filter((data, i) => {
    //             if (data.vid === item.vid) {
    //               index = i;
    //             }
    //           });
    //         }
    //         index !== undefined && this.failRfidData.splice(index, 1);
    //       }
    //     });
    //     let scanCardDom = document.querySelector(".box-card .el-card__body");
    //     scanCardDom &&
    //       (scanCardDom.scrollTop =
    //         scanCardDom.scrollHeight - scanCardDom.offsetHeight + 40);
    //   } else {
    //     /// add messages
    //     this.rfidData = vidList;
    //     this.failRfidData = vidList.filter(vid => vid.code != 1);
    //     console.log("rfidData,", this.rfidData);
    //   }
    //   this.orderVidData = this.rfidData;

    //   let result = [];
    //   console.log("confirm_this.rfidData", this.rfidData);
    //   this.rfidData.forEach(item => {
    //     if (item.code === 1) {
    //       result.push(item.vid);
    //     }
    //   });
    //   if (this.item.max === 1) {
    //     // 这边处理的原因是因为 confirm 后，页面上绑定的itemdata不会触发get钩子
    //     if (result.length === 1) {
    //       this.itemdata = result[0];
    //     } else if (result.length > 1) {
    //       this.itemdata = result.length;
    //     } else {
    //       this.itemdata = "";
    //     }
    //     console.log("this.itemdata", this.itemdata);
    //   } else {
    //     console.log("result.length", result.length);
    //     this.itemdata = result.length || "";
    //     if (result.length === 1) {
    //       this.itemdata = result[0];
    //     }
    //   }

    //   this.$nextTick(() => {
    //     this.solveBigVid();
    //   });
    // },
    // setCustomIdForReceive() {
    //   let customIdOnReceive = commonStoreModule.getCustomIdOnReceive;
    //   if (customIdOnReceive && this.fatherContent.key === "logistics_code" && (this.$route.query.scene && this.$route.query.scene === Scene.LOGISTIS_RECEIVE)) {

    //     this.selectVidsFromOrder([{
    //       vid: customIdOnReceive,
    //       code: 1
    //     }])
    //   }
    // }
  },

};
</script>

<style lang="scss" scoped>

</style>
