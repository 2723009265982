<template>
<div class="item-content" v-show="visible">
  <div class="form-item ve-upload">

    <div @click="uploadImage">
      <el-form ref="childForm" label-position="top" :inline="true" @submit.native.prevent :rules="rules" :model="formLabelAlign">
        <div class="item-tooltip-title" v-if="!isListItem">
          <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
          <el-tooltip class="item" effect="light" placement="bottom" v-if="fatherContent.displayReference.description">
            <div slot="content"> {{ fatherContent.displayReference.description }} </div>
            <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
          </el-tooltip>
        </div>

        <el-form-item style="width: 100%" prop="filename">
          <el-image style="width: 100%;height: 75vw;" class="upload-img" lazy v-if="fileList.length" :src="fileList[0].url" :fit="'contain'">
          </el-image>
          <el-upload action :on-change="onChange" :file-list="fileList" :on-remove="handleRemoveFile" :before-upload="beforeUpload" :disabled="disabled || cannotEdit" accept="image/png, image/jpeg, image/jpg">
            <template v-if="!fileList.length">
              <div class="upload_text">
                <img src="@/assets/new/upload_image.png" class="ve-upload-icon" />
                <span>{{$t('app.upload_img')}}</span>
              </div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>

  </div>
</div>
</template>

<script>
import callApp from "@/services/sdk";
import language from '@/utils/lang'
import VeTrueApi from '@/api/vetrue';

import {
  mapGetters
} from 'vuex'

export default {
  uuid: "00000000-0000-0000-0000-000000100005",
  components: {},
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host", "listHost", "contextData", "editStatus", "father", "contentLimit"],
  data() {
    return {
      itemdata: "",
      imageBase64: "",
      fileList: [],
      fileName: "",
      formLabelAlign: {
        filename: ''
      },
      tooltipVisible: false
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList',
      getChildPageContext: 'getChildPageContext'
    }),
    cannotEdit() {
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    content() {
      return Object.keys(this.jsonContent).length ?
        this.jsonContent :
        this.fatherContent;
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    },
    hostKey() {
      return this.host + "." + this.fatherContent.key
    },
    isListItem() {
      return this.father && this.father.itemType && Object.keys(this.father.itemType).length > 0
    },
    rules() {
      return {
        filename: [{
          required: this.fatherContent.controlReference.required,
          message: this.$t('app.required_error'),
          trigger: 'blur'
        }]
      }
    },
  },
  methods: {
    beforeUpload(image) {
      return false;
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      )
    },
    uploadImage(e) {
      if (e.target.className !== "el-upload__input") return;

      let plat = this.$route.query.plat;
      if (plat && plat === 'WEB') return;

      this.handleUpladByApp(e);
    },
    spliceUrl(location) {
      return window.Config.base_url + `/v1/public/attachment/${location}`;
    },
    handleRemoveFile(file) {
      this.imageBase64 = "";
      this.fileList = [];
      this.formLabelAlign = {
        filehash: "",
        filelocation: "",
        filename: "",
        filesize: "",
        filetype: ""
      }
      // 执行这里的代码后图片上传组件不显示了，但是标题label还有？
      // this.$emit("removeListContent", {
      //   key: this.fatherContent.key,
      //   value: file
      // });
    },
    // 兼容 web 平台使用
    onChange(image) {
      if (image) {
        this.image = image
        const fileObj = image !== null ? image.raw : null
        this.handleUploadFileInWeb(fileObj); //执行上传接口
      } else {
        console.log('FileReader API not supported: use the <form>, Luke!')
      }
    },
    async handleUploadFileInWeb(file) {
      let data = null;
      try {
        data = await VeTrueApi.uploadFile({ query: this.$route.query, file })
      } catch (error) {
        this.$message({ message: this.$t('feture.upload_failed'), type: "error", offset: 100, duration: 2000 , customClass: 'el-message-custom'});
        return;
      }
      this.imgFileList = [];
      this.itemdata = {
        filehash: "",
        filelocation: "",
        filename: "",
        filesize: "",
        filetype: ""
      };

      this.$store.state.domain = window.Config.base_url
      this.formLabelAlign = {}
      this.$nextTick(() => {
        let imageFile = data.data
        if (imageFile.filehash) {
          this.itemdata = imageFile;
          this.formLabelAlign = imageFile;
          this.fileName = imageFile.filename;
          this.imageBase64 = this.spliceUrl(imageFile.filehash);
          this.fileList = [{
            url: this.imageBase64,
            name: this.fileName
          }];
          console.log("file,", this.imageBase64);
        }
      });
    },

    handleUpladByApp(e) {
      callApp(
        "getImage", {
          type: "dataSource.file.image",
          data: {
            count: this.contentLimit,
            max: this.jsonContent.max,
            type: this.jsonContent.acceptedFileType || []
          }
        },
        "cbGetImage"
      ).then(data => {
        this.imgFileList = [];
        this.itemdata = {
          filehash: "",
          filelocation: "",
          filename: "",
          filesize: "",
          filetype: ""
        };

        this.formLabelAlign = {
          filehash: "",
          filelocation: "",
          filename: "",
          filesize: "",
          filetype: ""
        }
        this.$nextTick(() => {
          if (data.images) {
            if (data.images.length > 1) {
              let imgList = [...data.images]
              this.$emit("addListItems", {
                key: this.fatherContent.key,
                value: imgList
              });
            } else {
              this.itemdata = {
                ...data.images[0]
              };
              this.formLabelAlign = {
                ...data.images[0]
              };
              this.fileName = data.images[0] && data.images[0].filename;
              this.imageBase64 = this.spliceUrl(data.images[0].filehash);
              this.fileList = [{
                url: this.imageBase64,
                name: this.fileName,
                filehash: data.images[0].filehash
              }];
            }
          }
        });
      });
      e.preventDefault();
    }
  },
  created() {},
  mounted() {
    try {
      this.imageBase64 = this.spliceUrl(this.datamodel.filehash);
    } catch (error) {
      this.imageBase64 = "";
    }

    if (this.datamodel != undefined) {
      let fileName = this.datamodel && this.datamodel.filename;
      let filehash = this.datamodel && this.datamodel.filehash;
      this.imageBase64 = this.spliceUrl(this.datamodel.filehash);
      this.$nextTick(() => {
        fileName &&
          (this.fileList = [{
            url: this.imageBase64,
            name: fileName,
            filehash: filehash
          }]);
        this.formLabelAlign = this.datamodel;
      });
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: val.filelocation !== "" ? val : {}
        });
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
.ve-upload {
  .ve-upload-icon {
    width: 32px;
    position: relative;
    margin-top: 40px;
  }

  .el-upload {
    display: block;
  }

  .upload_text {
    display: flex;
    font-size: 14px;
    height: 130px;
    color: #A9A9B0;
    font-weight: 500;
    width: 100%;
    justify-self: center;
    align-items: center;
    flex-direction: column;
  }

  .el-form-item__content {
    text-align: center;
    background-color: #F9F9F9;
    border-radius: 2px;
  }

  .el-upload-list--picture-card {
    display: flex;
    justify-content: center;

    .el-upload-list__item {
      width: 100%;
      height: auto;
      margin-right: 0;
    }
  }

  .el-upload--picture-card {
    display: flex;
    flex-direction: column;
    width: 100%;

    img {
      align-self: center;
    }
  }

  .hiddenUpload {
    .el-upload {
      display: none !important;
    }
  }
}
</style>
