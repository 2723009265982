<template>
  <div class="form-item">
    <div v-for="(item,index) in jsonContent.attributes" :key="'basemodel'+index">
      <component
        v-if="registerTemplateName_SAVE.includes(`C${item.type.replace(/-/g, '')}`)"
        :is="`C${item.type.replace(/-/g, '')}`"
        :jsonContent="item.dataReference"
        :fatherContent="item"
        :datamodel="datamodel?datamodel[item.key]===false?false:(datamodel[item.key]):undefined"
        @fatherCall="setUpdateJSON"
        :host="hostKey"
        :listHost="hostKey"
        :disabled="disabled"
        @dcpDetail="viewDcpDetail"
        @updateRefDcu="updateRefDcuData"
        @cacheDatamodel="cacheDatamodelData"
        :jsonReferenceListContent="jsonReferenceListContent"
        :contextData="contextData"
        :editStatus="editStatus"
        ref="childComponents"
      ></component>
    </div>
  </div>
</template>

<script>
import render from "@/views/dcpdetail/render";
export default {
  uuid: "00000000-0000-0000-0000-000000100010",
  components: {
    render
  },
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host", "listHost","jsonReferenceListContent", "contextData", "editStatus"],
  data() {
    return {
      itemdata: "",
      callBackJson: {}
    };
  },
  computed: {
    hostKey() {
      return this.fatherContent.key || ""
    }
  },
  watch: {},
  methods: {
    updateRefDcuData(data){
      this.$emit("updateRefDcu", data)
    },
    cacheDatamodelData(){
      this.$emit("cacheDatamodel")
    },
    setUpdateJSON(data) {
      this.callBackJson[data.key] = data.value;
      if (data.value == undefined) {
        delete this.callBackJson[data.key];
      }
      this.$emit("fatherCall", {
        key: this.fatherContent.key,
        value: this.callBackJson
      });
    },
    viewDcpDetail(dcp){
      this.$emit("dcpDetail", dcp)
    }
  },
  created() {},
  mounted() {}
};
</script>
<style lang='scss' scoped>
</style>
