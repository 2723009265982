<template>
<div class="form-item" v-show="visible">
  <el-form ref="childForm" @submit.native.prevent :rules="rules" :model="formLabelAlign" label-position="top">
    <el-form-item label="" prop="value">
      <el-tooltip class="item" effect="light" placement="bottom" v-if="fatherContent.displayReference.description">
        <div slot="content">{{fatherContent.displayReference.description}}</div>
        <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
      </el-tooltip>

      <div class="ref-content">
        <span class="ve-list-widget label">{{localized(`label`)}}</span>
        <el-button style="position: absolute;top: 10px;right: 0px;" type="text" v-if="!disabled" disabled :style="{opacity: 1}" @click="addDcp">
          <img src="@/assets/icons/dcu/add.png" style="position: relative;z-index: 1001" :style="{opacity: 1}" width="30px" />
        </el-button>

        <div class="ref-item">
          <div class="item">
            <template v-if="refDcpList.length">
              <div class="ref-item-content" v-for="(dcp , index) in refDcpList" :key="index">
                <div class="dataname" @click="viewDcpDetail(dcp)">
                  <span>{{$t('app.dcp_data_name')}}: {{dcp.dataName}}</span>
                  <div class="right">
                    <div v-if="dcp.hasUpdate">New</div>
                    <i class="el-icon-arrow-right" />
                  </div>
                </div>
                <div class="buname" v-if="dcp.fromBuName">
                  <span>{{$t('app.dcp_bu')}}: {{dcp.fromBuName}}</span>
                </div>
                <div class="buname" v-if="dcp.fromProjectName">
                  <span>{{$t('app.dcp_project')}}: {{dcp.fromProjectName}}</span>
                </div>
                <div class="buname" v-if="dcp.fromDcpName">
                  <span>{{$t('app.dcp_type')}}: {{dcp.fromDcpName}}</span>
                </div>
                <div class="updatetime" v-if="dcp.updateTime">
                  <span>{{$t('app.dcp_update_date')}}: {{dateFormat(dcp.updateTime)}}</span>
                  <div v-if="!disabled" @click="removeDcp(dcp)">
                    <img src="@/assets/icons/dcu/delete.png" :style="{opacity: disabled ? '.5' : 1}" width="20px" />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-form-item>
  </el-form>
  <mt-actionsheet :cancelText="$t('app.dcp_cancel')" closeOnClickModal="true" :actions="dataSource" v-model="sheetVisible"></mt-actionsheet>
</div>
</template>

<script>
import language from '@/utils/lang';
import {
  mapGetters
} from 'vuex'

export default {
  uuid: "00000000-0000-0000-0000-000000100001",
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host", "listHost", "contextData", "editStatus"],
  components: {},
  data() {
    return {
      itemdata: "",
      formLabelAlign: {
        value: ""
      },
      dataSource: [{
        name: this.$t("app.no_data"),
        value: -1
      }],
      sheetVisible: false,
      selectedData: {},
      previewdcplist: [],
      cdCxt: {}
    };
  },
  computed: {
    ...mapGetters([
      'getChildPageContext',
      'getViewDcp'
    ]),
    refContent() {
      return (this.getChildPageContext[this.hostKey] && this.getChildPageContext[this.hostKey].refContent) || this.datamodel
    },
    refDcpList() {
      var refList = (this.getChildPageContext[this.hostKey] && this.getChildPageContext[this.hostKey].refContent) || []
      var pvList = this.previewdcplist.filter(el => {
        let existList = refList.filter(element => {
          return element.dataHash === el.dataHash
        })
        return existList.length <= 0
      })
      if (this.getViewDcp && this.getViewDcp.replaceOrigin) {
        pvList = pvList.map(item => {
          if (item.latestDataHash === this.getViewDcp.latestDataHash) {
            return {
              ...item,
              dataHash: item.latestDataHash
            }
          } else {
            return item
          }
        })
      }
      return (pvList || []).concat(refList)
    },
    rules() {
      return {
        value: [{
          required: this.fatherContent.controlReference.required,
          message: this.$t('app.required_error'),
          trigger: "change"
        }]
      };
    },
    showtext() {
      const filter = this.dataSource.filter(
        item => this.itemdata === item.value
      )[0];
      return filter && filter.name;
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    },
    hostKey() {
      return this.host + "." + this.fatherContent.key
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: val.value
        });
      },
      deep: true
    },
    refDcpList: {
      handler(val) {
        var mapperVal = val.map(element => {
          return {
            clauseindex: element.clauseIndex,
            datahash: element.dataHash,
            datauuid: element.dataUuid,
            datavid: element.dataVid,
            txid: element.txid,
            uuid: element.dataUuid
          }
        })
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: mapperVal
        });
      },
      deep: true
    }
  },
  methods: {
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      );
    },
    callSelect() {
      if (this.disabled) {
        return;
      }
      this.sheetVisible = true;
    },
    addDcp() {
      let key = this.fatherContent.key;
      let uuid = this.$store.state.uuid;
      let projectId = this.$store.state.projectId;
      let refContent = (this.getChildPageContext[this.hostKey] && this.getChildPageContext[this.hostKey].refContent) || []
      let context = {
        father: {
          key,
          uuid,
          projectId
        },
        hostKey: this.hostKey,
        refContent
      }
      let childPageContext = this.getChildPageContext
      childPageContext[this.hostKey] = context
      this.$store.dispatch('setChildPageContext', childPageContext)
      this.$emit("cacheDatamodel")
      this.$router.push({
        path: "/v2/dcplist",
        query: {
          hostKey: this.hostKey,
          type: "dcp",
          old: true
        }
      });
    },
    removeDcp(dcp) {
      return;
      // let refObject = this.getChildPageContext[this.hostKey]
      // if (refObject) {
      //   refObject["refContent"] = refObject && refObject.refContent.filter(element => {
      //     return element.dataHash != dcp.dataHash
      //   })
      // }

      // let childPageContext = JSON.parse(JSON.stringify(this.getChildPageContext))
      // childPageContext[this.hostKey] = refObject
      // this.$store.dispatch('setChildPageContext', childPageContext)

      // this.previewdcplist = this.previewdcplist.filter(item => {
      //   return item.dataHash != dcp.dataHash
      // })
    },
    viewDcpDetail(dcp) {
      return;
      // this.$emit("dcpDetail", dcp)
    },
    dateFormat(date = '', format = 'yyyy-MM-dd HH:mm:ss') {
      if (/^.*T.*.0{3}\+0{4}$/.test(date)) {
        // eslint-disable-next-line no-useless-escape
        date = new Date(date.replace(/\-/g, '/').replace('T', ' ').replace(/\.\d{3}/, ' GMT'))
      }
      const types = (val) => {
        return Object.prototype.toString.call(val).match(/^\[\w+\s+(\w+)\]$/i)[1].toLowerCase()
      }
      var isDate = (val) => {
        return types(val) === 'date'
      }
      const toNumber = (num) => {
        return parseFloat(num)
      }
      const coverData = (obj = '', n = 2) => {
        obj = obj.toString()
        if (obj.length >= n) {
          return obj
        }
        obj = '0000000000' + obj
        return obj.substr(obj.length - n)
      }
      if (isDate(date)) {
        date = Date.parse(date)
      }
      if (!/^\d{10,}$/.test(date)) {
        return ''
      }
      date = new Date(toNumber((date.toString() + '0000000000000').substr(0, 13)))
      let y = date.getFullYear()
      let M = date.getMonth() + 1
      let d = date.getDate()
      let h = date.getHours()
      let m = date.getMinutes()
      let s = date.getSeconds()
      format = format.replace('yyyy', y)
      format = format.replace('MM', coverData(M))
      format = format.replace('M', M)
      format = format.replace('dd', coverData(d))
      format = format.replace('d', d)
      format = format.replace('HH', coverData(h))
      format = format.replace('H', h)
      format = format.replace('mm', coverData(m))
      format = format.replace('m', m)
      format = format.replace('ss', coverData(s))
      format = format.replace('s', s)
      return format
    }
  },
  created() {},
  mounted() {
    var mapperVal = this.refDcpList.map(element => {
      return {
        clauseindex: element.clauseIndex,
        datahash: element.dataHash,
        datauuid: element.dataUuid,
        datavid: element.dataVid,
        txid: element.txid,
        uuid: element.dataUuid
      }
    })
    this.$emit("fatherCall", {
      key: this.fatherContent.key,
      value: mapperVal
    });
  }
};
</script>

<style lang="scss" scoped>
.ref-content {
  padding-top: 20px;
}

.ref-item {
  .item {
    min-height: 80px;
    border-radius: 5px;
    border: 1px solid #ccc;

    .ref-item-content {
      margin-left: 10px;
      margin-right: 10px;
      padding-top: 0px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #ccc;

      .dataname {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right {
          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            line-height: 20px;
            padding-left: 5px;
            padding-right: 5px;
            margin-right: 5px;
            font-size: 12px;
            color: red;
            border: 1px solid red;
            border-radius: 5px;
          }
        }
      }

      .buname {
        line-height: 30px;
      }

      .updatetime {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 40px;
      }
    }

    :last-child {
      border-bottom: none;
    }
  }
}

.ve-list-widget {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
</style>
