<template>
<div class="item-content" v-show="visible">
  <div class="form-item ve-upload">
    <div @click="uploadFile">
      <el-form ref="childForm" label-position="top" :inline="true" @submit.native.prevent :rules="rules" :model="formLabelAlign">

        <div class="item-tooltip-title" v-if="!isListItem">
          <span v-if="fatherContent.controlReference && fatherContent.controlReference.required">*</span> <span>{{localized(`label`)}}</span>
          <el-tooltip class="item" effect="light" placement="bottom" v-if="fatherContent.displayReference.description">
            <div slot="content"> {{ fatherContent.displayReference.description }} </div>
            <img class="form-tooltip" :src="require('@/assets/icons/dcu/question.png')" />
          </el-tooltip>
        </div>

        <el-form-item style="width: 100%" prop="filename">
          <video style="width: 100%;height: 70vw;background-color: #4A4A4A" controls="controls" v-if="fileList.length" :src="fileList[0].url"></video>
          <el-upload action :file-list="fileList" :on-change="onChange" :on-remove="handleRemoveFile" :before-upload="beforeUpload" :disabled="disabled || cannotEdit" accept="video/*">
            <template v-if="!fileList.length">
              <div class="upload_text">
                <img src="@/assets/new/upload_video.png" class="ve-upload-icon" />
                <span>{{$t('app.upload_video')}}</span>
                <el-progress v-if="percentage && !isDone" :percentage="percentage" :stroke-width="10" :format="format" style="width: 300px;" />
              </div>
            </template>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
  </div>
</div>
</template>

<script>
import callApp from "@/services/sdk";
import language from '@/utils/lang'
import VeTrueApi from '@/api/vetrue';

import {
  mapGetters
} from 'vuex'

export default {
  uuid: "00000000-0000-0000-0000-000000100006",
  components: {},
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host", "contextData", "listHost", "editStatus", "father"],
  data() {
    return {
      itemdata: "",
      videoBase64: "",
      fileName: "",
      fileList: [],
      formLabelAlign: {},
      Loading: null,
      percentage: 0,
      isDone: false,
    };
  },
  computed: {
    ...mapGetters({
      formStatus: "getFormStatus",
      getDisabledKeyPathList: 'getDisabledKeyPathList',
      getChildPageContext: 'getChildPageContext'
    }),
    cannotEdit() {
      let disabledKeyPath = this.getDisabledKeyPathList.includes(this.dataKey)
      return !this.fatherContent.displayReference.editable || this.disabled || disabledKeyPath
    },
    dataKey() {
      return this.listHost + '.' + this.fatherContent.key
    },
    content() {
      return Object.keys(this.jsonContent).length ?
        this.jsonContent :
        this.fatherContent;
    },
    visible() {
      if (this.fatherContent.itemType) {
        return this.fatherContent.itemType.displayReference.visible;
      }
      return this.fatherContent.displayReference.visible;
    },
    hostKey() {
      return this.host + "." + this.fatherContent.key
    },
    isListItem() {
      return this.father && this.father.itemType && Object.keys(this.father.itemType).length > 0
    },
    rules() {
      return {
        filename: [{
          required: this.fatherContent.controlReference.required,
          message: this.$t('app.required_error'),
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    format() {
      return this.percentage === 100 ? '上传完成' : `上传中 ${this.percentage}%`;
    },
    startLoading() {
      this.Loading = this.openLoading(this.$t('feture.uploading'));
    },
    endLoading() {
      this.Loading.close();
    },
    // 兼容 web 平台使用
    onChange(video) {
      if (video) {
        const fileObj = video !== null ? video.raw : null;
        console.log(fileObj, '视频上传');
        this.handleUploadFileInWeb(fileObj); //执行上传接口
      } else {
        console.log('FileReader API not supported: use the <form>, Luke!')
      }
    },
    async handleUploadFileInWeb(file) {
      // this.startLoading();
      this.isDone = false;
      const that = this;
      this.$nextTick(() => {
        if (!that.isDone) {
          setInterval(() => {
            if (that.percentage < 70) {
              that.percentage += 20;
            }
          }, 500)
        }
      });

      const data = await VeTrueApi.uploadFile({ query: this.$route.query, file })
      this.imgFileList = [];
      this.itemdata = {
        filehash: "",
        filelocation: "",
        filename: "",
        filesize: "",
        filetype: ""
      };

      this.$store.state.domain = window.Config.base_url;
      this.formLabelAlign = {}
      this.$nextTick(() => {
        let imageFile = data.data
        if (imageFile.filehash) {
          this.itemdata = imageFile;
          this.formLabelAlign = imageFile;
          this.fileName = imageFile.filename;
          this.videoBase64 = this.spliceUrl(imageFile.filehash);
          this.fileList = [{
            url: this.videoBase64,
            name: this.fileName
          }];
        }
        that.percentage = 100;
        that.isDone = true;
      });

      // this.endLoading();
    },
    spliceUrl(location) {
      return window.Config.base_url + `/v1/public/attachment/${location}`;
    },
    localized(key) {
      return language.localize(
        this.$store.state,
        this.fatherContent.key,
        key,
        this.fatherContent.displayReference[key]
      )
    },
    beforeUpload() {
      return false;
    },
    uploadFile(e) {
      if (e.target.className !== "el-upload__input") return;
      let plat = this.$route.query.plat;
      if (plat && plat === 'WEB') return;

      this.itemdata = {
        filehash: "",
        filelocation: "",
        filename: "",
        filesize: "",
        filetype: ""
      };
      callApp(
        "getVideo", {
          type: "dataSource.file.video",
          data: {
            max: this.content.controlReference.max,
            type: this.content.controlReference.acceptedFileType || []
          }
        },
        "cbGetVideo"
      ).then(data => {
        this.fileList = [];
        this.formLabelAlign = {}
        this.$nextTick(() => {
          if (data.videos) {
            this.itemdata = {
              ...data.videos[0]
            };
            this.formLabelAlign = {
              ...data.videos[0]
            };
            this.fileName = data.videos[0].filename;
            this.videoBase64 = this.spliceUrl(data.videos[0].filehash);
            this.fileList = [{
              url: this.videoBase64,
              name: this.fileName
            }];
            this.$emit("fatherCall", {
              key: this.fatherContent.key,
              value: this.fatherContent.filelocation !== "" ? this.fatherContent : {}
            });
          }
        });
      });
      e.preventDefault();
    },
    handleRemoveFile() {
      this.percentage = 0;
      this.videoBase64 = "";
      this.fileList = [];
      this.formLabelAlign = {
        filehash: "",
        filelocation: "",
        filename: "",
        filesize: "",
        filetype: ""
      }
    }
  },
  created() {},
  mounted() {
    this.fileName = this.datamodel && this.datamodel.filename;
    this.videoBase64 = this.datamodel && this.spliceUrl(this.datamodel.filehash);
    this.fileName && (this.fileList = [{
      url: this.videoBase64,
      name: this.fileName
    }]);

    if (this.datamodel != undefined) {
      this.$nextTick(() => {
        this.formLabelAlign = this.datamodel;
      });
    }
  },
  watch: {
    formLabelAlign: {
      handler(val) {
        this.$emit("fatherCall", {
          key: this.fatherContent.key,
          value: val.filelocation !== "" ? val : {}
        });
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.ve-upload {

  .upload_text {
    display: flex;
    font-size: 14px;
    height: 150px;
    color: #A9A9B0;
    font-weight: 500;
    width: 100%;
    justify-self: center;
    align-items: center;
    flex-direction: column;
  }

  .item-content {
    padding-top: 10px;
    text-align: center;
  }

  .ve-upload-icon {
    width: 32px;
    position: relative;
    margin-top: 40px;
  }

  .el-upload__text em {
    color: #9b9b9b !important;
    font-weight: 500;
  }

  .el-upload-list--picture-card {
    display: flex;
    justify-content: center;

    .el-upload-list__item {
      width: 100%;
      height: auto;
      margin-right: 0;
    }
  }

  .el-upload--picture-card {
    display: flex;
    flex-direction: column;
    width: 100%;

    img {
      align-self: center;
    }
  }

  .el-upload__text {
    font-size: 14px;
    line-height: 48px;
  }

}
</style>
