import { render, staticRenderFns } from "./vid.vue?vue&type=template&id=28666e64&scoped=true&"
import script from "./vid.vue?vue&type=script&lang=js&"
export * from "./vid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28666e64",
  null
  
)

export default component.exports