<template>
  <div class="form-item">
    <div v-for="(item,index) in jsonContent.attributes" :key="'basemodel'+index">
      <component
        v-if="registerTemplateName_SAVE.includes(`C${item.type.replace(/-/g, '')}`)"
        :is="`C${item.type.replace(/-/g, '')}`"
        :jsonContent="item.dataReference"
        :fatherContent="item"
        :datamodel="datamodel ? (datamodel[item.key]===false ? false : datamodel[item.key]) : undefined"
        @fatherCall="setUpdateJSON"
        :disabled="disabled"
        :host="hostKey"
        :listHost="hostKey"
        @dcpDetail="viewDcpDetail"
        @updateRefDcu="updateRefDcuData"
        @cacheDatamodel="cacheDatamodelData"
        :jsonReferenceListContent="jsonReferenceListContent"
        :contextData="contextData"
        :editStatus="editStatus"
        ref="childComponents"
      ></component>
      <C00000000000000000000000000000000
          :jsonContent="item.dataReference || []"
          ref="childComponents"
          :jsonReferenceListContent="jsonReferenceListContent"
          :fatherContent="item"
          :datamodel="datamodel?datamodel[item.key]===false?false:(datamodel[item.key]):undefined"
          @fatherCall="setUpdateJSON"
          @updateRefDcu="updateRefDcuData"
          @cacheDatamodel="cacheDatamodelData"
          :disabled="disabled"
          :host="hostKey"
          :listHost="hostKey"
          :editStatus="editStatus"
          v-else
        ></C00000000000000000000000000000000>
    </div>
    <!-- <el-form-item :label="localized(`label`)" :rules="rules" :prop="item.prop">
            <el-input v-model="itemdata" :placeholder="localized(`prompt`) || ''"  :disabled="!item.editable" @focus="showInputView"></el-input>
    </el-form-item>-->
  </div>
</template>

<script>
export default {
  uuid: "00000000-0000-0000-0000-000000100011",
  components: {},
  props: ["jsonContent", "fatherContent", "datamodel", "disabled", "host", "listHost","jsonReferenceListContent", "contextData", "editStatus"],
  data() {
    return {
      itemdata: "",
      callBackJson: {},
      receiveDcp: "",
      fatherCallJson: {}
    };
  },
  computed: {
    hostKey() {
      return this.fatherContent.key || ""
    }
  },
  watch: {},
  methods: {
    updateRefDcuData(data){
      this.$emit("updateRefDcu", data)
    },
    cacheDatamodelData(){
      this.$emit("cacheDatamodel")
    },
    setUpdateJSON(data) {

      this.callBackJson[data.key] = data.value;
      if (data.value == undefined) {
        delete this.callBackJson[data.key];
      }
      this.$emit("fatherCall", {
        key: this.fatherContent.key,
        value: this.callBackJson
      });
    },
    viewDcpDetail(dcp){
      this.receiveDcp = dcp
      this.$emit("dcpDetail", dcp)
    }
  },
  created() {},
  mounted() {}
};
</script>
<style lang='scss' scoped>
</style>
